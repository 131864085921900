import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
    
} from "react-google-maps";
import Modal from 'react-modal';
import style from './style.module.css';

const MapWithAMarker = withScriptjs(withGoogleMap( props => {
    const { order, getOrderDelivery } = props;
    
    const [ orderDelivery, setOrderDelivery ] = useState(
        {
            "deliveryLat": "32.060647",
            "deliveryLon": "34.7741449",
            "shop": {
                "shopLat": props.shop.shopLat,
                "shopLon": props.shop.shopLon
            },
            "delivery": null
        }
    );

    const icons = new Map([
        ['yango', 'assets/delivery/CourierYango.png'],
        ['wolt', 'assets/delivery/CourierWolt.png'],
    ]);
    const { t } = useTranslation();
    const [ info, setInfo ] = useState({
        marker: {},
        visible: false,
        selectedPlace: {}
    });
    useEffect(() => {
        const prevLocation ={ lat: 0, lon: 0};
        const loopManager = { tries: 10, etaUpdate: true};

        const fetchPosition = () => {
            order?.id && getOrderDelivery(order.id, loopManager.etaUpdate).then(resp => {
                if(resp?.order) {
                    const { carrierLat, carrierLon } = resp.order.delivery;
                    loopManager.etaUpdate = false;
                    if (carrierLat && carrierLon && loopManager.tries>0) {
                        setOrderDelivery(resp.order)
                        prevLocation.lat === carrierLat && 
                        prevLocation.lon === carrierLon && loopManager.tries--;
                        prevLocation.lat = carrierLat;
                        prevLocation.lon = carrierLon;
                    } else {
                        loopManager.tries = 10;
                        !carrierLat && setOrderDelivery({
                            ...resp.order,
                            ...{delivery: {...getDefaultPosition(resp.order), codename: resp.order.delivery.codename}}
                        })
                    }
                    
                    props.setEta(resp.order.delivery?.ETA ?? 0);
                }
            })
        }

        fetchPosition();
        const intervalId = setInterval(() => {
            loopManager.tries > 0 ? fetchPosition() : clearInterval(intervalId);
        }, 15000);

        const etaUpdateInterval= setInterval(() => {
            loopManager.etaUpdate = true;
        }, 45000);

        return () => {clearInterval(intervalId); clearInterval(etaUpdateInterval);} 
    }, [order, getOrderDelivery])

    const getDefaultPosition = (order) => {

        const toRadians = (degrees) => degrees * (Math.PI/180);
        const toDegrees = (radians) => radians * (180/Math.PI);

        const latm = (toRadians(order.deliveryLat) + toRadians(order.shop.shopLat))/2;
        const lonm = (toRadians(order.deliveryLon) + toRadians(order.shop.shopLon))/2;
        return { 
            carrierLat: toDegrees(latm),
            carrierLon: toDegrees(lonm),
        };
    }
    const onMarkerClik = (props, marker, e) => { setInfo({selectedPlace: props, marker: marker, visible: true});}
    const onMapClicked = (props) => { if (info.visible) setInfo({selectedPlace: {}, marker: {}, visible: false}) }
    return (
        <GoogleMap
            defaultZoom={14}
            defaultCenter={
                !orderDelivery.delivery?
                    { lat: props.shop.shopLat, lng: props.shop.shopLon }: 
                    { lat: parseFloat(orderDelivery.delivery.carrierLat), lng: parseFloat(orderDelivery.delivery.carrierLon) }
            }
            onClick={onMapClicked}
        >
            <Marker
                name={'Your Location'}
                onClick={onMarkerClik}
                position={{ lat: parseFloat(orderDelivery.deliveryLat), lng: parseFloat(orderDelivery.deliveryLon) }}
            />
            {!!orderDelivery.delivery && <Marker 
                name={'courier location'}
                onClick={onMarkerClik}
                position={{ lat: parseFloat(orderDelivery.delivery.carrierLat), lng: parseFloat(orderDelivery.delivery.carrierLon) }}
                {...icons.has(orderDelivery.delivery.codename) ? 
                    {icon:{
                        url: icons.get(orderDelivery.delivery.codename),
                        scaledSize: { width: 40, height: 40 },
                    }} : {}
                }
                >
                    {info.visible && <InfoWindow marker={info.marker} visible={info.visible} > 
                        <div><h1>{t('courier location')}</h1></div>
                    </InfoWindow>}
                </Marker>
            }
            <Marker 
                name={props.shop.shopName}
                onClick={onMarkerClik}
                position={{ lat: orderDelivery.shop.shopLat, lng: orderDelivery.shop.shopLon }}
            />
        </GoogleMap>
    )}
));

const CurierLocation = inject("myShopApiStore")(observer((props) => {
    const { shop, getOrderDelivery } = props.myShopApiStore;
    const brdRadius =  getComputedStyle(document.querySelector(':root')).getPropertyValue('--brdRadius')
    return (
        <div style={{zIndex: 12, height: props.height ?? '300px'}}>
            <MapWithAMarker 
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '100%' }} />}
                mapElement={<div style={{ height: '100%', borderRadius: `0 0 ${brdRadius} ${brdRadius}` }} />}

                getOrderDelivery={getOrderDelivery}
                order={props.order}
                setEta={props.setEta}
                shop={shop}
            />
        </div>
    )

}));

export const LocationModal = (props) => {
    const modalStyle = {
        content : {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            borderRadius: '20px',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            height: '90%',
            // maxHeight: '100%',
            // maxWidth: '100%',
            padding: '0',
            border: 'none',
            // backgroundColor: 'transparent',
            zIndex: '12'
        },
        overlay: {
            backgroundColor: "rgb(0, 0, 0, 0.65)",
            zIndex: 10
        }
    }

    const [ eta, setEta ] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        props.order?.delivery?.trackingUrl && (modalStyle.content.height='100%')
    }, [props.order?.delivery?.trackingUrl, modalStyle.content]);

    const iphoneUrl = (url) => url.replace('https://', '//');

    return (
        <Modal style={modalStyle} isOpen={props.modalIsOpen} 
            onRequestClose={props.closeModal} 
        >
            <div className={style.ModalContainer}>
                    <div className={style.CancelXBtn}>
                        <span onClick={props.closeModal}>✕</span>
                    </div>
                    
                    <div style={{ marginBottom: 0, flex: 1}}>
                        {!props.order?.delivery?.trackingUrl 
                            ? <>
                                <div className={style.CourierInfo} style={{ height:'120px'}}>
                                    <h1 style={{ marginBottom: '0' }}>{t('courier location')}</h1>
                                    {!eta || <h2 style={{ margin: '0'}}>{t('Courier will be in about', {time: eta})}</h2>}
                                </div>
                                <CurierLocation order={props.order} setEta={setEta} height='calc(100% - 120px)' />
                            </>
                            : <iframe 
                                title={props.title ?? 'courier location'}
                                src={props.order.delivery.trackingUrl} 
                                style={{width: '100%', height: '100%'}}>
                              </iframe>
                        }
                    </div>
            </div>
        </Modal>
    )
}

export default CurierLocation;