import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import Item from './Item';
// import CurierLocation from '../CurierLocation';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class Basket extends React.Component {
    
    handleAddToCartClick = () => {
        const { basketHistory, addToCart, closeCartItemForm} = this.props.myShopApiStore;

        if(basketHistory){
            basketHistory.products.forEach(product => {
                addToCart(product.shopProduct, product.unitType);
                closeCartItemForm();
            }); 
        }
    }

    render() {
        const { basketHistory } = this.props.myShopApiStore;
        const { t } = this.props;
        return (
            <div className={style.container}>
                <div className={style.CancelXBtn}>
                    <span onClick={this.props.hideBasket}>✕</span>
                </div>

                <div id="" className={style.TopDiv}>
                    {
                        !basketHistory ||
                            <div className={style.OrderNumber} >{` ${t('_order number')} #${basketHistory.id}`}</div>
                    }


                    <div className={style.List}>
                        {
                            !basketHistory?.products ||
                                basketHistory.products.map((item, index) => {
                                    return(
                                        <div key={`basket_item_${index}`} className={style.ItemWrapper}
                                            //style={{padding: '5px 0 5px 0', borderBottom: '2px solid rgba(0,0,0,.2)'}}
                                        >
                                            <Item item={item} index={index} key={`p_${index}_${item.id}`} />
                                        </div>
                                    )
                                })
                        }
                    </div>
                    {/* <div className={style.ButtonWrapper}>
                        <div className={style.button}
                            style={{border: `1px solid ${mainColor}`, color: mainColor}}
                            onClick={this.handleAddToCartClick}
                            >
                                העתק לסל
                        </div>
                    </div> */}

                    {/* {this.props.isCourierVisible && <CurierLocation order={basketHistory}/>} */}
                  
                </div>
            </div>
        )
    }
}

export default withTranslation()(Basket);