import logo from "../../assets/logo.png";
import bigappslogo from "../../assets/bigappslogo.png";
import loginicon from "../../assets/user.png";
import React, { Component, Suspense } from "react";
import style from "./style.module.css";
// import Popup from './Popup';
import { observer, inject } from "mobx-react";
import TopMenu from "./TopMenu";
import AppBody from "./AppBody";
import Maintenance from "./Maintenance";
import WelcomeModal from './WelcomeModal';
import WelcomeImageModal from './WelcomeImageModal';
import stores from '../../assets/stores.png'
import whatsapp from '../../assets/whatsapp.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { my_env } from '../../Stores/myShopApi'
import cartImg from "assets/cart_x2.png";
import NumberFormat from "react-number-format";
import { useScreenType } from '../../hooks/useScreenType';
import CarouselModal from "./AppBody/ShopMenu/ItemList/Item/CarouselModal";
import ChooseOrderTypeModal from "Components/StoresScreen/ChooseOrderTypeModal";
import ReactWhatsapp from 'react-whatsapp';
import whatsappIcon from "../../assets/whatsapp.svg"
import facebook from "../../assets/facebook.svg"
import Footer from "./Footer";
import blackCart from 'assets/cart/blackCart.png'
import redCart from 'assets/cart/redCart.png'
import blueCart from 'assets/cart/blueCart.png'
import greenCart from 'assets/cart/greenCart.png'
import yellowCart from 'assets/cart/yellowCart.png'
import whiteCart from 'assets/cart/whiteCart.png'
import OrderTypeSwitch from "./AppBody/Payment/ShippingOptionForm/OrderTypeSwitch";
import { Beforeunload } from 'react-beforeunload';
import WelcomeShopsImageModal from '../WelcomeShopsImageModal';
import LanguageSelect from "languageSelect";
import { withTranslation } from 'react-i18next';


@inject((store) => {
  const { ifaceMngr, myShopApiStore } = store;
  return { ifaceMngr, myShopApiStore };
})
@observer
class MainScreen extends Component {
  constructor(props) {
    super(props);

    const { orderData, shop, shopsData} = props.myShopApiStore;

    this.isPriceOrderType = (shopsData?.shops?.find(item=>item.id === this.shopId)?.isPriceOrderType 
    || shop?.isPriceOrderType);
  
    this.state = {
      modalIsOpen: !this.isPriceOrderType,  //welcome modal
      modalImageIsOpen: true,  //welcome image modal
      modalShopsImageIsOpen: true,  //welcome image modal
      carouselModalIsOpen: true,
      isMobile: window.innerWidth <= 850,
      basketIsOpen: false,
      chooseOrderTypeModal: !!this.isPriceOrderType,
      isPriceOrderType: !!this.isPriceOrderType, // ONLY FOR THE SINGLE STORE FIRST LOAD
      //chosenShop: props.myShopApiStore.shop,
      shopId: null,
    };
  }

  // componentDidUpdate(prevProps) {
  //   // const isOrderTypeModal = this.props.myShopApiStore.shop?.isPriceOrderType && !this.props.myShopApiStore.orderData?.orderType;

  //   // if (isOrderTypeModal && isOrderTypeModal !== this.state.chooseOrderTypeModal && this.state.modalIsOpen) 
  //   //   this.setState({modalIsOpen: !isOrderTypeModal, chooseOrderTypeModal: isOrderTypeModal});    
  // }

  // openCarouselModalIsOpen = () => {
  //   this.setState({carouselModalIsOpen: true});
  // }
  // closeCarouselModalIsOpen = () => {
  //   this.setState({carouselModalIsOpen: false});
  // }

  openBasket = () => {
    this.setState({basketIsOpen: true});
  }
  
  closeBasket = () => {
    this.setState({basketIsOpen: false});
  }

  updateIsMobile = () => {
    this.setState({ isMobile: window.innerWidth <= 850 });
  }

  componentDidMount(){
    const { openStore, storeOpened, shop, shopId, shopsData, shopData, getShopsResp, getApiResp_SetShop, preOrderType } = this.props.myShopApiStore;
    (!shopData || shop.id !==shopId) && getApiResp_SetShop();
    !shopsData && getShopsResp(); //Even if it is a single store we should check it on start (or after reload).
    openStore(shopId);
  
    (this.props.myShopApiStore.shopsData?.shops?.find(item=>item.id === this.shopId)?.isPriceOrderType 
      || this.props.myShopApiStore.shop?.isPriceOrderType) 
      && this.setState({modalIsOpen: !!preOrderType, chooseOrderTypeModal: !preOrderType}); 

    this.props.ifaceMngr.setActiveTopMenu('ShopMenu');

    this.setState({'shopId': shopId});

    window.addEventListener('resize', this.updateIsMobile);
  }

  componentDidUpdate(prevProps, prevState){
    // console.log(this.props.myShopApiStore?.orderTypesChecked)
    //ONLY FOR SINGLE STORE FIRST LOAD
    if(this.state.modalIsOpen 
      && this.props.myShopApiStore.isSingleStore 
      && this.props.myShopApiStore.shop?.isPriceOrderType 
      && !!this.props.myShopApiStore.shop?.isPriceOrderType !== prevState.isPriceOrderType) 
        this.setState({modalIsOpen: false, chooseOrderTypeModal: true, isPriceOrderType: true});

      document.documentElement.style.setProperty('--mainColor', this.props.myShopApiStore.mainColor);
  }

  componentWillUnmount() {
    this.props.ifaceMngr.setActiveTopMenu('ShopMenu');
    window.removeEventListener('resize', this.updateIsMobile);
  }

  closeModal = () => {
    this.setState({modalIsOpen: false})
  }

  closeImageModal = () => {
    this.setState({modalImageIsOpen: false})
    this.props.ifaceMngr.setWelcomeShopImageModalShown(this.state.shopId);
  }

  closeShopsImageModal = () => {
    this.setState({modalShopsImageIsOpen: false})
    this.props.ifaceMngr.setWelcomePlusImageModalShown();
  }

  openChooseOrderTypeModal = (shop) => {
    this.setState({chosenShop: shop});
    this.setState({chooseOrderTypeModal: true});
  }
  closeChooseOrderTypeModal = () => {
      this.setState({chooseOrderTypeModal: false});
  }

  toggleBasket = () => {
    const { isCartItemUpdating, isPackItemUpdating } = this.props.myShopApiStore;

    if(this.props.ifaceMngr.activeTopMenu !=="ShopMenu") this.props.ifaceMngr.setActiveTopMenu("ShopMenu");

    if(!isCartItemUpdating && !isPackItemUpdating){
      this.setState({basketIsOpen: !this.state.basketIsOpen})
    }
  }

  fastToggleBasket = () => {
    this.setState({basketIsOpen: !this.state.basketIsOpen});
  }

  handleCloseStore = async () => {
    const { closeStore, isPackItemUpdating, isCartItemUpdating, thisCart, getShopsResp } = this.props.myShopApiStore;
    if(isPackItemUpdating || isCartItemUpdating){
      thisCart.cartItemList.pop();
    }

    getShopsResp();
    closeStore();

  }

  getCartIcon = () => {
    if(window.innerWidth <= 850) return whiteCart;

    const { mainColor } = this.props.myShopApiStore;

    const colorPallet = {
      '#EB0028': redCart,
      '#1BB449': greenCart,
      '#00B1E1': blueCart,
      '#F1A103': yellowCart,
    }

    return colorPallet[mainColor]??blackCart;
  }

  render() {
    // const { contextMenuVisible, userSettingsVisible, selectedGroupId, popup } = this.props.helperStore;
    const { userToken, mainColor, userParams, closeStore, shop, cartPrice, shopsData, shopId, thisCart, my_env, isSingleStore, orderData, orderTypesOvertime, blockClosing } = this.props.myShopApiStore;
    const { setActiveTopMenu, activeTopMenu } = this.props.ifaceMngr;
    const { t } = this.props;
    const { currencyName } = this.props.myShopApiStore.shopData?.country ?? { currencyName: '₪' };
    //this.setBgImage();

    // const isSingleStore = !shopsData && shopId === 1;
    //const isSingleStore = !shopsData;

    // const isfullscreen =  window.matchMedia("(max-width: 850px)").matches

    let itemsInCart = 0;
    thisCart?.cartItemList?.forEach(cartItem => {cartItem.isReadyToOrder && itemsInCart++})

    if(!shop) return null;
    return (
      <div
        className={style.TopApp}
        style={{  
                background: my_env.url.indexOf("fast") >= 0 ? 'none' : (`url(` + (this.state.isMobile ? (shop.backgroundImage ?? (my_env.url + "/upload/bg/" + shop.backgroundCodename + ".jpg") ) : (shop.backgroundAdminImage ?? (my_env.url + "/upload/bg_admin/" + shop.backgroundCodename + ".jpg"))) + `) center center / cover no-repeat #FFFFFF`)
              }}
      >
      {
      my_env.url.indexOf("fast") >= 0
      ?
        <ReactWhatsapp style={{cursor: 'pointer', position: 'absolute', top: '-1000px'}} number={process.env.REACT_APP_WHATSAPP_PHONE} message={process.env.REACT_APP_WHATSAPP_MSG}>
          <div className={style.Whatsapp} >
              <img src={whatsapp} width="55px" alt='' />
          </div>
        </ReactWhatsapp>
      :
        ''
      }

        {/* <CarouselModal modalIsOpen={this.state.carouselModalIsOpen} closeModal={this.closeCarouselModalIsOpen} shop={shop}/> */}
        {
          shopsData.welcomeImageSite == null?
            ''
          :
            <WelcomeShopsImageModal modalIsOpen={this.state.modalShopsImageIsOpen && !this.props.ifaceMngr.isWelcomePlusImageModalShown} isMobile={this.state.isMobile} closeModal={this.closeShopsImageModal}/>
        }
        {
          shop.welcomeImageSite == null ?
            <WelcomeModal modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal}/>
          :
            <WelcomeImageModal modalIsOpen={this.state.modalImageIsOpen && !this.props.ifaceMngr.WelcomeShopImageModalShownList.includes(this.state.shopId)} isMobile={this.state.isMobile} closeModal={this.closeImageModal} closeStore={this.handleCloseStore}/>
        }
        { blockClosing
          ?
            <Beforeunload onBeforeunload={(event) => {event.preventDefault();}}/>
          :
            ''
        }
        <ChooseOrderTypeModal modalIsOpen={this.state.chooseOrderTypeModal} shop={this.props.myShopApiStore.shop} closeModal={this.closeChooseOrderTypeModal} bgColor={this.props.myShopApiStore.mainColor}/>
        <div className={style.App}>
          <div className={style.AppHeader}>
          {(shopsData && shopsData.shops?.length) && !this.state.isMobile ?
            <div className={style.GoToStoresBtn}>
              <div className={style.StoresImg}>
                <LazyLoadImage effect='blur' title={t('ToStoresScreen')} src={stores} alt="Stores" onClick={this.handleCloseStore} /> 
              </div>
            </div> : null
          }
          {
            !this.state.isMobile
            ?
              <div className={style.AppHeader__Login}>
                <LanguageSelect />                

                <div className={style.SocialIcons}>
                    {
                      shop.linkFacebook
                      ?
                        <img alt="" src={facebook} width="40px" onClick={()=> window.open(shop.linkFacebook, "_blank")}/>
                      :
                        ''
                    }
                    {
                      shop.phoneWhatsapp
                      ?
                        <ReactWhatsapp style={{backgroundColor: 'transparent', border: 'unset',
                                               outline: 'none', width: "50px"}}
                                       number={shop.phoneWhatsapp} message=''>
                            <img alt="" src={whatsappIcon} width="100%" height="100%"/>
                        </ReactWhatsapp>
                      :
                        ''
                    }
                </div>
                <div className={style.DivLogin} style={{cursor: 'default'}}>
                  <div
                    className={style.LogIconDiv}
                    onClick={() =>
                      /*!userToken && */ this.props.myShopApiStore.setSearchValue('', true) && this.props.ifaceMngr.setActiveTopMenu("Registration")
                    }
                    title={userToken ? t('LoggedIn') : t('LogIn') } 
                    style={{cursor: /*userToken ? 'default' : */ 'pointer'}}
                  >
                    <LazyLoadImage
                      effect='blur'
                      className={style.LoginIcon}
                      src={loginicon}
                      alt="login"
                    />
                  </div>
                  <div className={style.LoginText}
                    onClick={() =>
                      /*!userToken && */ this.props.myShopApiStore.setSearchValue('', true) && this.props.ifaceMngr.setActiveTopMenu("Registration")
                    }
                    style={{cursor: 'pointer'}}
                  >
                    {userToken ? (
                      <>
                        <div>{t('Hi', {context: 'user', user: userParams.name})}</div>
                        {/* <div>{userParams.name}</div> */}
                      </>
                    ) : (
                      <>
                        <div>{t('Hi', {context: 'guest'})}</div>
                        <div className={style.LoginHere}>{t("SignUp")}</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            :<>
            

            <div style={{display: 'flex', flexDirection: 'column', width:'70%', alignItems: 'center'}}>
              

              <div className={style.CartInfo} onClick={this.toggleBasket}>{/*for mobile size*/}
                {
                  shop.isBusiness
                  ?
                    ''
                  :
                    <div className={style.CartInfoTitle}>
                        {t('total')}
                    </div>
                }
                <div className={style.CartSum}>
                  <span className={style.CartSumSh}>{shop.isBusiness ? '' : currencyName}</span>
                  {
                    shop.isBusiness
                    ?
                      ''
                    :
                      <span className={style.CartSumVal}>
                        <NumberFormat
                          value={cartPrice}
                          // prefix={curr}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                        />
                      </span>
                  }
                </div>
                <div className={style.CartWrapper}>
                  <span className={style.ItemsInCart} style={{backgroundColor: mainColor}}>
                    <span>{itemsInCart}</span>
                  </span>
                  <img className={style.CartImg} src={this.getCartIcon()} alt="cart" style={{backgroundColor: "transparency"}} />
                </div>
                
              </div>
              
              {
                activeTopMenu !== "Payment" && shop?.isPriceOrderType
                ?
                  <OrderTypeSwitch 
                    changeOrderType={(toType)=>{
                      toType !== orderData.orderType 
                      && this.props.myShopApiStore.setOrderData('orderType', toType);
                    }}
                    orderType={orderData?.orderType}
                    mainColor={mainColor}
                    shop={shop}
                    thisCart={thisCart}
                    orderTypesOvertime={orderTypesOvertime}
                  />
                :
                  ''
              }
            </div>
            <LanguageSelect isMobile={true} />
            </>
          }

            <div className={style.AppTopMenu}>
              <TopMenu basketIsOpen={this.state.basketIsOpen} closeBasket={this.closeBasket} toggleBasket={this.toggleBasket} fastToggleBasket={this.fastToggleBasket}/>
            </div>

            {
              this.state.isMobile
              ? ''
              :<>
                <div className={style.AppLogo} onClick={() => setActiveTopMenu("ShopMenu")}>
                  {shop ? <img src={shop.image && !isSingleStore ? shop.image : my_env.url + '/logo.png'} className="AppLogo" alt="AppLogo" /> : null}
                </div>
                

              </>
            }  

          </div>

          <div id="appBody" className={style.AppBody}>
            {this.props.myShopApiStore.isMaintenance ? (
              <Maintenance />
            ) : (
              <AppBody basketIsOpen={this.state.basketIsOpen} openBasket={this.openBasket} closeBasket={this.closeBasket} toggleBasket={this.toggleBasket} fastToggleBasket={this.fastToggleBasket}/>
            )}
          </div>


          {
            !this.state.isMobile
            ?
              <div id="appFooter" className={style.AppFooter}>
                <Footer />
              </div>
            :
              ""
          }
        </div>
      </div>
    );
  }
}
const MyComponent = withTranslation()(MainScreen);
export default function App() {
  return (
    <Suspense fallback="loading">
      <MyComponent />
    </Suspense>
  )
}
