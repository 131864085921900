import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';
import StoredHistory from "./StoredHistory";
import { IoIosArrowBack } from "react-icons/io";

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})
@observer
class History extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
            isMobile: window.innerWidth <= 850,
            basketVisible: false,
        }

        this.updateIsMobile = this.updateIsMobile.bind(this);
    }

    hideBasket = () => {
        this.setState({basketVisible: false})
    }
    
    showBasket = () => {
        this.setState({basketVisible: true})
    }
    
    updateIsMobile = () => {
        this.setState({ isMobile: window.innerWidth <= 850 });
      }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile);
    }

    // hist = this.props.myShopApiStore.getHistory();
    componentWillUnmount = () => {
        const { resetOrdersHistory, setBasketHistory, resetHistoryPage } = this.props.myShopApiStore;
        resetOrdersHistory();
        //setBasketHistory(null);
        resetHistoryPage();

        window.removeEventListener('resize', this.updateIsMobile);
    }
    
    render() {
        const { ordersHistory, mainColor, setBasketHistory } = this.props.myShopApiStore;
        const { t } = this.props;
        return (
        <div className={style.container}>
            <div className={style.Header}>
                <div>
                    {t('History')}
                </div>
                {
                    this.state.isMobile && ordersHistory?.length && this.state.basketVisible
                    ?
                        <IoIosArrowBack color='#FFF' style={{cursor: 'pointer', zIndex: 0}} onClick={this.hideBasket}/>
                    :
                        ''
                }
            </div>
            <StoredHistory
                basketVisible={this.state.basketVisible}
                showBasket={this.showBasket}
                hideBasket={this.hideBasket}
                isMobile={this.state.isMobile}
                />
        </div>
        )
    }
}

export default withTranslation()(History);
