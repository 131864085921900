import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Basket from './Basket';
import HistItem from './HistItem';
import Empty from "../Empty";
import Spinner from 'Components/Spinner';
import { LocationModal } from './CurierLocation';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})
@observer
class StoredHistory extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             interval: null,
             times: null,
             loading: false,
             isCourierVisible: false,
        }
    }

    handleScroll = async e => {
        const { scrollHeight, scrollTop, clientHeight } = e.target;
        const { getOrderHistoryResp } = this.props.myShopApiStore;
        // console.log(e)
        // console.log('scrollHeight:', e.target.scrollHeight);
        // console.log('scrollTop:', Math.floor(e.target.scrollTop));
        // console.log('clientHeight:', e.target.clientHeight);
        
        if(scrollHeight - Math.floor(scrollTop) === clientHeight ||
                scrollHeight - Math.ceil(scrollTop) === clientHeight){
            const histResp = await getOrderHistoryResp()
        }
    }

    componentDidMount = async () => {
        const { ordersHistory, getOrderHistoryResp } = this.props.myShopApiStore;

        if(!ordersHistory?.length && !this.state.loading){
            this.setState({loading: true})
            await getOrderHistoryResp()
            this.setState({loading: false})
        }   
    }

    componentWillUnmount = () => {

        this.state.loading && this.setState({loading: false})
    }

    getShippingStatus = (orderId) => {
        let shippingStatus = null;
        this.state.times && this.state.times.forEach(time => {
            if(orderId === time.orderId){
                shippingStatus = {...time};
            }
        })
        return shippingStatus;
    }

    render() {
        const { ordersHistory, basketHistory } = this.props.myShopApiStore;
        const { isMobile } = this.props;
        
        return (
            <div className={style.container}>
                {
                   this.state.loading
                    ?             
                    <div className={style.Spinner}>
                        <Spinner loader='BeatLoader' loading={this.state.loading}
                                color={this.props.myShopApiStore.mainColor}
                                />
                    </div> 
                    : (!!ordersHistory?.length || <div className={style.Spinner}><Empty hideBasket={this.props.hideBasket}/></div>) 
                }
                <div id="" className={style.TopDiv}>
                    <div className={style.ESpace}></div>
                    <div className={style.HistList} onScroll={ordersHistory.length ? this.handleScroll : null}>
                        {
                            !!ordersHistory?.length && (!isMobile || !this.props.basketVisible) 
                            &&
                                ordersHistory.map((order, idx) => 
                                    <React.Fragment key={idx}> 
                                        <HistItem order={order}
                                            isFirstOrder={!idx}
                                            // getShippingStatus={this.getShippingStatus}
                                            showBasket={this.props.showBasket}
                                            basketVisible={this.props.basketVisible}
                                            isCourierVisible = {this.state.isCourierVisible}
                                            showCourier={(show = !this.state.isCourierVisible) => { this.setState({...this.state, isCourierVisible: show});} }
                                           
                                        />
                                    </React.Fragment>)
                        }
                    </div>
                    {
                        (isMobile ? this.props.basketVisible : ordersHistory?.length)
                        ? 
                            <div className={isMobile ? style.mobileContBasket : style.contBasket}>
                                <div className={style.Basket}>
                                    <Basket hideBasket={this.props.hideBasket} isCourierVisible={this.state.isCourierVisible}/>
                                </div>
                            </div>
                        :
                            ''
                    }
                </div>
                <LocationModal 
                    order={basketHistory} 
                    modalIsOpen={this.state.isCourierVisible} 
                    closeModal={() => this.setState({...this.state, isCourierVisible: false})}
                    />
            </div>
        )
    }
}

export default StoredHistory;
